.TravelDate-Inner-Div {
    min-height: 100vh;
}

.TravelDate-Grid-Container {
    min-height: 100vh;
    width: 100%;
    background-image: url("../../images/yellow-header-rectangle-curve.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.TravelDate-Logo {
    height: auto;
    max-height: 150px;
    width: auto;
    max-width: 150px;
}

.TravelDate-Logo-Default {
    height: 135px;
    width: 135px;
    background-image: url("../../images/default-image-3.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
}

.TravelDate-Text {
    padding-top: 5vh;
    padding-bottom: 5vh;
    text-align: center;
}