@import url(https://fonts.googleapis.com/css?family=Quicksand:300,400,500,600,700&display=swap);
body {
  margin: 0;
  font-family: 'Quicksand';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.TOIntro-Inner-Div {
    min-height: 100vh;
}

.TOIntro-Parent-Div {
    min-height: 100vh;
}

.TOIntro-Grid-Container {
    min-height: 100vh;
    width: 100%;
    background-image: url(/static/media/yellow-header-rectangle-curve.d5868feb.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#TOIntro-Logo {
    height: auto;
    max-height: 250px;
    width: auto;
    max-width: 250px;
}

.TOIntro-Logo-Default {
    height: 150px;
    width: 150px;
    background-image: url(/static/media/default-image-3.9ffa3f9a.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
}

.TOIntro-Text {
    padding-top: 5vh;
    padding-bottom: 5vh;
    text-align: center;
}
.NumTravellers-Inner-Div {
    min-height: 100vh;
}

.NumTravellers-Grid-Container {
    min-height: 100vh;
    width: 100%;
    background-image: url(/static/media/yellow-header-rectangle-curve.d5868feb.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.NumTravellers-Logo {
    height: auto;
    max-height: 150px;
    width: auto;
    max-width: 150px;
}

.NumTravellers-Logo-Default {
    height: 135px;
    width: 135px;
    background-image: url(/static/media/default-image-3.9ffa3f9a.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
}

.NumTravellers-Text {
    padding-top: 5vh;
    padding-bottom: 5vh;
    text-align: center;
}
.SectionTheme-Inner-Div {
    min-height: 100vh;
}

.SectionTheme-Parent-Div {
    min-height: 100vh;
}

.SectionTheme-Grid-Container {
    min-height: 100vh;
    width: 100%;
    background-image: url(/static/media/yellow-header-rectangle-curve.d5868feb.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 5vh;
}

.SectionTheme-Logo {
    height: auto;
    max-height: 250px;
    width: auto;
    max-width: 250px;
}

.SectionTheme-Logo-Default {
    height: 150px;
    width: 150px;
    background-image: url(/static/media/default-image-3.9ffa3f9a.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
}


.SectionTheme-Text {
    padding-top: 5vh;
    padding-bottom: 5vh;
    text-align: center;
}

.SectionTheme-Logo-Container {
    padding-top: 5vh;
    padding-bottom: 5vh;
}

@media screen and (max-width: 700px) {
    .SectionTheme-Logo-Default {
        height: 100px;
        width: 100px;
    }
    .SectionTheme-Logo-Container {
        padding-top: 2vh;
        padding-bottom: 2vh;
    }
    .SectionTheme-Logo-Container {
        padding-top: 3vh;
        padding-bottom: 2vh;
    }
}

.Home-Card-Component {
    min-height: 100%;
}

.Home-Card-Div-Heading {
    background-color: #F76C6C;
    min-height: 20vh;
    text-align: center;
    vertical-align: middle;
}

.Home-Card-Div-Heading-Selected {
    background-color: #374785;
    min-height: 20vh;
    text-align: center;
    vertical-align: middle;
}

.Home-Card-Font-Heading {
    font-weight: 700;
    color: white;
    padding-top: 7vh;
}

.Home-Card-Button:hover {
    background-color: rgb(250, 143, 143);
    cursor: pointer;
}

.Home-Card-Spacing {
    margin-bottom: 4vh;
}

.SectionThemeCard-Padding {
    padding-top: 3vh;
    padding-bottom:3vh;
}

.Exp-Card-Div-Heading-Selected {
    height: 50vh;
    text-align: center;
    vertical-align: middle;
    background-position:center center;
    position: relative;
    background-size: cover;
}

.Exp-Card-Image {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.Exp-Card-Dialog-Image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    padding-bottom: 1vh;
    padding-top: 1vh;
}

.Exp-Card {
    height: 30vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.Exp-Card-Overlay {
    position: absolute;
    top: 0;
    left: 0;
    clear: float;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    color: #ffffff;
  }

.Exp-Card-Overlay-Coloured {
    position: absolute;
    top: 0;
    left: 0;
    clear: float;
    width: 100%;
    height: 100%;
    background-color: rgba(99, 99, 97, 0.719);
    color: #ffffff;
  }

.Exp-Card-Text-Overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    color: white;
    transform: translate(-50%, -50%);
}

.Exp-Card-Text-Spacing {
    padding-top: 1vh;
    padding-bottom: 1vh;
}
.itnerary-map-map {
    width: 100%;
    height: 30vh;
    margin-top: 5vh;
    margin-bottom: 5vh;
}

.itinerary-day-plan-text-container-test {
    white-space: normal;
}

.itinerary-day-plan-experience {
     width:100%;
     height:40vh;
     display:flex;
     justify-content:center;
     align-items:center;
     overflow:hidden;
     margin-bottom: 2vh;
     margin-top: 2vh;
     margin-right: 2vw;
     margin-left:2vw;
     position: relative;
     text-align: center;
     color: white;
 }

 .itinerary-day-plan-experience-default {
    position: relative;
    text-align: center;
    color: white;
    width:80%;
    height:40vh;
    display:flex;
    justify-content:center;
    align-items:center;
    overflow:hidden;
    margin-bottom: 2vh;
    margin-top: 2vh;
    margin-right: 2vw;
    margin-left:2vw;
}

 .itinerary-day-plan-default {
     width: 100%;
     height: 100%;
     background-color: #374785;
 }

 .itinerary-day-plan-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
 }

 .itinerary-day-plan-experience img{
   width: 100%;
   height: 100%;
   object-fit: cover;
   position: relative;
 }

 @media only screen and (min-width: 600px) {

    .itinerary-day-plan-experience {
        width:60%;
        height:40vh;
        display:flex;
        overflow:hidden;
        text-align: center;
        justify-self: center;
        align-items: center;
        align-items: flex-end;
        margin-bottom: 2vh;
        margin-top: 0.5vh;
        margin-right: 2vw;
        margin-left:2vw;
    }
    .itinerary-day-plan-experience img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
    }

 }


.itinerary-display-section-div-left {
     width:100%; 
     max-height:40vh; 
     object-fit: cover;
     display:flex;
     justify-content:center;
     align-items:center;
     overflow:hidden;
     margin-bottom: 0.5vw;
     margin-right: 1vw;
 }

 .itinerary-display-section-div-left-bg {
    width: 100em;
    height: 100%;
    background-color: #374785;
}

.itinerary-display-section-div-left-bg-2 {
    width: 100em;
    height: 100%;
    background-color: #F8E9A1
}

.itinerary-display-section-div-right-bg-1 {
    width: 100em;
    height: 100%;
    background-color: #F76C6C;
}

.itinerary-display-section-div-right-bg-2 {
    width: 100em;
    height: 100%;
    background-color: #A8D0E6
}

 .itinerary-display-section-div-left img{
   width: 100%;
   height: 100%;
   object-fit: cover;
   position: relative;
 }

 .itinerary-display-section-div-right {
    width:100%;
     height:60vh;
     object-fit: cover;
     display:flex;
     justify-content:center;
     align-items:center;
     overflow:hidden;
     margin-bottom: 0.5vw;
     margin-left: 1vh;
     margin-right: 1vh;
 }
 .itinerary-display-section-div-right img{
   width: 100%;
   height: 100%;
   object-fit: cover;
   position: relative;
   margin-right: 1vw;
 }

 .itinerary-display-section-header-text {
    width: 100%;
    height: 100%;
    vertical-align: middle;
    margin: 5vh;
}



@media only screen and (max-width: 800px) {
    .itinerary-display-section-header-mobile {
        display: none;
    }
}
 @media only screen and (max-width: 500px) {
    .itinerary-display-section-div-left {
        width:100%;
        height:20vh;
        display:flex;
        justify-content:center;
        align-items:center;
        overflow:hidden;
        margin-bottom: 0.5vw;
        margin-right: 0.5vw;
     }

     .itinerary-display-section-div-right {
        width:100%;
         height:40vh;
         display:flex;
         justify-content:center;
         align-items:center;
         overflow:hidden;
         margin-bottom: 0.5vw;
         margin-left: 0.5vh;
     }
     .itinerary-display-section-div-right img{
       width: 100%;
       height: 100%;
       object-fit: cover;
       position: relative;
       margin-right: 1vw;
     }


     .itinerary-display-section-header-text {
        width: 100%;
        height: 100%;
        vertical-align: middle;
        align-items: center;
        margin: 5vh;
    }

 }

.itinerary-overview-section-button {
    background: #DEB666;
    border-radius: 10px;
    min-width: 25vw;
    min-height: 5vh;
    color: white;
    font-family: "Quicksand";
    font-size: 1rem;
    padding: 1%;
    border-color: #DEB666;
    border-style: solid;
}

.itinerary-overview-section-text-spacing {
    margin-top: 2vh;
}

.itinerary-overview-section-component-spacing {
    margin-top: 4vh;
    margin-bottom: 4vh;
}

.itinerary-overview-section-button:hover {
    background: rgb(216, 194, 150);
    border-radius: 10px;
    min-width: 25vw;
    min-height: 5vh;
    color: white;
    font-family: "Quicksand";
    font-size: 1rem;
    padding: 1%;
    border-color: #DEB666;
    border-style: solid;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {

    .itinerary-overview-section-button {
        background: #DEB666;
        border-radius: 10px;
        min-width: 50vw;
        min-height: 5vh;
        color: white;
        font-family: "Quicksand";
        font-size: 1rem;
        padding: 1%;
        border-color: #DEB666;
        border-style: solid;
    }

    .itinerary-overview-section-button:hover {
        background: rgb(216, 194, 150);
        border-radius: 10px;
        min-width: 50vw;
        min-height: 5vh;
        color: white;
        font-family: "Quicksand";
        font-size: 1rem;
        padding: 1%;
        border-color: #DEB666;
        border-style: solid;
        cursor: pointer;
    }

 }
#HeaderText {
    font-weight: 600;
    text-align: center;
}

#DescriptionText {
    font-weight: 'light';
    font-family: 'Quicksand';
}

.paragraph-text-outer-div {
    padding-top: 15vh;
    padding-bottom: 15vh;
}
.itnerary-map-map {
    width: 100%;
    height: 70vh;
    margin-top: 5vh;
    margin-bottom: 5vh;
}
.itinerary-day-plan-text-container-test {
    white-space: normal;
}

.itinerary-local-operator-image {
     width:60%;
     height:40vh;
     display:flex;
     justify-content:center;
     align-items:center;
     overflow:hidden;
     margin: 2vh;
 }
 .itinerary-local-operator-image img{
   width: 100%;
   height: 100%;
   object-fit: contain;
   position: relative;
 }

 @media only screen and (max-width: 600px) {

    .itinerary-local-operator-image {
        width:100%;
        height:40vh;
        display:flex;
        overflow:hidden;
        text-align: center;
        justify-self: center;
        align-items: center;
        align-items: flex-end;
        margin: 2vh;
    }
    .itinerary-local-operator-image img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
    }

 }


.itinerary-local-operator-operator-reviews {
    margin-top: 8vh;
    margin-bottom: 8vh;
    background-color: #F5F5F5;
}

.itinerary-local-operator-review-text {
    padding-top: 4vh;
    padding-bottom: 4vh;
}

.itinerary-local-operator-review-card-section {
    padding-top: 2vh;
    padding-bottom: 6vh;
}

.itinerary-operator-button-spacing {
    margin-bottom: 5vh;
}
.Luxury-Inner-Div {
    min-height: 100vh;
}

.Luxury-Grid-Container {
    min-height: 100vh;
    width: 100%;
    background-image: url(/static/media/yellow-header-rectangle-curve.d5868feb.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.Luxury-Logo {
    height: auto;
    max-height: 150px;
    width: auto;
    max-width: 150px;
}

.Luxury-Logo-Default {
    height: 135px;
    width: 135px;
    background-image: url(/static/media/default-image-3.9ffa3f9a.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
}

.Luxury-Text {
    padding-top: 5vh;
    padding-bottom: 5vh;
    text-align: center;
}
.NumDays-Inner-Div {
    min-height: 100vh;
}

.NumDays-Grid-Container {
    min-height: 100vh;
    width: 100%;
    background-image: url(/static/media/yellow-header-rectangle-curve.d5868feb.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.NumDays-Logo {
    height: auto;
    max-height: 150px;
    width: auto;
    max-width: 150px;
}

.NumDays-Logo-Default {
    height: 135px;
    width: 135px;
    background-image: url(/static/media/default-image-3.9ffa3f9a.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
}

.NumDays-Text {
    padding-top: 5vh;
    padding-bottom: 5vh;
    text-align: center;
}
.Name-Inner-Div {
    min-height: 100vh;
}

.Name-Grid-Container {
    min-height: 100vh;
    width: 100%;
    background-image: url(/static/media/yellow-header-rectangle-curve.d5868feb.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.Name-Logo {
    height: auto;
    max-height: 150px;
    width: auto;
    max-width: 150px;
}

.Name-Logo-Default {
    height: 135px;
    width: 135px;
    background-image: url(/static/media/default-image-3.9ffa3f9a.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
}

.TOIntro-Text {
    padding-top: 5vh;
    padding-bottom: 5vh;
    text-align: center;
}
.TravelDate-Inner-Div {
    min-height: 100vh;
}

.TravelDate-Grid-Container {
    min-height: 100vh;
    width: 100%;
    background-image: url(/static/media/yellow-header-rectangle-curve.d5868feb.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.TravelDate-Logo {
    height: auto;
    max-height: 150px;
    width: auto;
    max-width: 150px;
}

.TravelDate-Logo-Default {
    height: 135px;
    width: 135px;
    background-image: url(/static/media/default-image-3.9ffa3f9a.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
}

.TravelDate-Text {
    padding-top: 5vh;
    padding-bottom: 5vh;
    text-align: center;
}

