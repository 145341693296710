.itinerary-day-plan-text-container-test {
    white-space: normal;
}

.itinerary-day-plan-experience {
     width:100%;
     height:40vh;
     display:flex;
     justify-content:center;
     align-items:center;
     overflow:hidden;
     margin-bottom: 2vh;
     margin-top: 2vh;
     margin-right: 2vw;
     margin-left:2vw;
     position: relative;
     text-align: center;
     color: white;
 }

 .itinerary-day-plan-experience-default {
    position: relative;
    text-align: center;
    color: white;
    width:80%;
    height:40vh;
    display:flex;
    justify-content:center;
    align-items:center;
    overflow:hidden;
    margin-bottom: 2vh;
    margin-top: 2vh;
    margin-right: 2vw;
    margin-left:2vw;
}

 .itinerary-day-plan-default {
     width: 100%;
     height: 100%;
     background-color: #374785;
 }

 .itinerary-day-plan-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
 }

 .itinerary-day-plan-experience img{
   width: 100%;
   height: 100%;
   object-fit: cover;
   position: relative;
 }

 @media only screen and (min-width: 600px) {

    .itinerary-day-plan-experience {
        width:60%;
        height:40vh;
        display:flex;
        overflow:hidden;
        text-align: center;
        justify-self: center;
        align-items: center;
        align-items: flex-end;
        margin-bottom: 2vh;
        margin-top: 0.5vh;
        margin-right: 2vw;
        margin-left:2vw;
    }
    .itinerary-day-plan-experience img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
    }

 }

