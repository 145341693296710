.itinerary-display-section-div-left {
     width:100%; 
     max-height:40vh; 
     object-fit: cover;
     display:flex;
     justify-content:center;
     align-items:center;
     overflow:hidden;
     margin-bottom: 0.5vw;
     margin-right: 1vw;
 }

 .itinerary-display-section-div-left-bg {
    width: 100em;
    height: 100%;
    background-color: #374785;
}

.itinerary-display-section-div-left-bg-2 {
    width: 100em;
    height: 100%;
    background-color: #F8E9A1
}

.itinerary-display-section-div-right-bg-1 {
    width: 100em;
    height: 100%;
    background-color: #F76C6C;
}

.itinerary-display-section-div-right-bg-2 {
    width: 100em;
    height: 100%;
    background-color: #A8D0E6
}

 .itinerary-display-section-div-left img{
   width: 100%;
   height: 100%;
   object-fit: cover;
   position: relative;
 }

 .itinerary-display-section-div-right {
    width:100%;
     height:60vh;
     object-fit: cover;
     display:flex;
     justify-content:center;
     align-items:center;
     overflow:hidden;
     margin-bottom: 0.5vw;
     margin-left: 1vh;
     margin-right: 1vh;
 }
 .itinerary-display-section-div-right img{
   width: 100%;
   height: 100%;
   object-fit: cover;
   position: relative;
   margin-right: 1vw;
 }

 .itinerary-display-section-header-text {
    width: 100%;
    height: 100%;
    vertical-align: middle;
    margin: 5vh;
}



@media only screen and (max-width: 800px) {
    .itinerary-display-section-header-mobile {
        display: none;
    }
}
 @media only screen and (max-width: 500px) {
    .itinerary-display-section-div-left {
        width:100%;
        height:20vh;
        display:flex;
        justify-content:center;
        align-items:center;
        overflow:hidden;
        margin-bottom: 0.5vw;
        margin-right: 0.5vw;
     }

     .itinerary-display-section-div-right {
        width:100%;
         height:40vh;
         display:flex;
         justify-content:center;
         align-items:center;
         overflow:hidden;
         margin-bottom: 0.5vw;
         margin-left: 0.5vh;
     }
     .itinerary-display-section-div-right img{
       width: 100%;
       height: 100%;
       object-fit: cover;
       position: relative;
       margin-right: 1vw;
     }


     .itinerary-display-section-header-text {
        width: 100%;
        height: 100%;
        vertical-align: middle;
        align-items: center;
        margin: 5vh;
    }

 }
