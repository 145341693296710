.TOIntro-Inner-Div {
    min-height: 100vh;
}

.TOIntro-Parent-Div {
    min-height: 100vh;
}

.TOIntro-Grid-Container {
    min-height: 100vh;
    width: 100%;
    background-image: url("../../images/yellow-header-rectangle-curve.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#TOIntro-Logo {
    height: auto;
    max-height: 250px;
    width: auto;
    max-width: 250px;
}

.TOIntro-Logo-Default {
    height: 150px;
    width: 150px;
    background-image: url("../../images/default-image-3.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
}

.TOIntro-Text {
    padding-top: 5vh;
    padding-bottom: 5vh;
    text-align: center;
}