.itinerary-overview-section-button {
    background: #DEB666;
    border-radius: 10px;
    min-width: 25vw;
    min-height: 5vh;
    color: white;
    font-family: "Quicksand";
    font-size: 1rem;
    padding: 1%;
    border-color: #DEB666;
    border-style: solid;
}

.itinerary-overview-section-text-spacing {
    margin-top: 2vh;
}

.itinerary-overview-section-component-spacing {
    margin-top: 4vh;
    margin-bottom: 4vh;
}

.itinerary-overview-section-button:hover {
    background: rgb(216, 194, 150);
    border-radius: 10px;
    min-width: 25vw;
    min-height: 5vh;
    color: white;
    font-family: "Quicksand";
    font-size: 1rem;
    padding: 1%;
    border-color: #DEB666;
    border-style: solid;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {

    .itinerary-overview-section-button {
        background: #DEB666;
        border-radius: 10px;
        min-width: 50vw;
        min-height: 5vh;
        color: white;
        font-family: "Quicksand";
        font-size: 1rem;
        padding: 1%;
        border-color: #DEB666;
        border-style: solid;
    }

    .itinerary-overview-section-button:hover {
        background: rgb(216, 194, 150);
        border-radius: 10px;
        min-width: 50vw;
        min-height: 5vh;
        color: white;
        font-family: "Quicksand";
        font-size: 1rem;
        padding: 1%;
        border-color: #DEB666;
        border-style: solid;
        cursor: pointer;
    }

 }