.SectionTheme-Inner-Div {
    min-height: 100vh;
}

.SectionTheme-Parent-Div {
    min-height: 100vh;
}

.SectionTheme-Grid-Container {
    min-height: 100vh;
    width: 100%;
    background-image: url("../../images/yellow-header-rectangle-curve.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 5vh;
}

.SectionTheme-Logo {
    height: auto;
    max-height: 250px;
    width: auto;
    max-width: 250px;
}

.SectionTheme-Logo-Default {
    height: 150px;
    width: 150px;
    background-image: url("../../images/default-image-3.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
}


.SectionTheme-Text {
    padding-top: 5vh;
    padding-bottom: 5vh;
    text-align: center;
}

.SectionTheme-Logo-Container {
    padding-top: 5vh;
    padding-bottom: 5vh;
}

@media screen and (max-width: 700px) {
    .SectionTheme-Logo-Default {
        height: 100px;
        width: 100px;
    }
    .SectionTheme-Logo-Container {
        padding-top: 2vh;
        padding-bottom: 2vh;
    }
    .SectionTheme-Logo-Container {
        padding-top: 3vh;
        padding-bottom: 2vh;
    }
}
