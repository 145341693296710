#HeaderText {
    font-weight: 600;
    text-align: center;
}

#DescriptionText {
    font-weight: 'light';
    font-family: 'Quicksand';
}

.paragraph-text-outer-div {
    padding-top: 15vh;
    padding-bottom: 15vh;
}