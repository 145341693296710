.itinerary-day-plan-text-container-test {
    white-space: normal;
}

.itinerary-local-operator-image {
     width:60%;
     height:40vh;
     display:flex;
     justify-content:center;
     align-items:center;
     overflow:hidden;
     margin: 2vh;
 }
 .itinerary-local-operator-image img{
   width: 100%;
   height: 100%;
   object-fit: contain;
   position: relative;
 }

 @media only screen and (max-width: 600px) {

    .itinerary-local-operator-image {
        width:100%;
        height:40vh;
        display:flex;
        overflow:hidden;
        text-align: center;
        justify-self: center;
        align-items: center;
        align-items: flex-end;
        margin: 2vh;
    }
    .itinerary-local-operator-image img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
    }

 }


.itinerary-local-operator-operator-reviews {
    margin-top: 8vh;
    margin-bottom: 8vh;
    background-color: #F5F5F5;
}

.itinerary-local-operator-review-text {
    padding-top: 4vh;
    padding-bottom: 4vh;
}

.itinerary-local-operator-review-card-section {
    padding-top: 2vh;
    padding-bottom: 6vh;
}

.itinerary-operator-button-spacing {
    margin-bottom: 5vh;
}