.Home-Card-Component {
    min-height: 100%;
}

.Home-Card-Div-Heading {
    background-color: #F76C6C;
    min-height: 20vh;
    text-align: center;
    vertical-align: middle;
}

.Home-Card-Div-Heading-Selected {
    background-color: #374785;
    min-height: 20vh;
    text-align: center;
    vertical-align: middle;
}

.Home-Card-Font-Heading {
    font-weight: 700;
    color: white;
    padding-top: 7vh;
}

.Home-Card-Button:hover {
    background-color: rgb(250, 143, 143);
    cursor: pointer;
}

.Home-Card-Spacing {
    margin-bottom: 4vh;
}

.SectionThemeCard-Padding {
    padding-top: 3vh;
    padding-bottom:3vh;
}