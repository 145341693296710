.Exp-Card-Div-Heading-Selected {
    height: 50vh;
    text-align: center;
    vertical-align: middle;
    background-position:center center;
    position: relative;
    background-size: cover;
}

.Exp-Card-Image {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.Exp-Card-Dialog-Image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    padding-bottom: 1vh;
    padding-top: 1vh;
}

.Exp-Card {
    height: 30vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.Exp-Card-Overlay {
    position: absolute;
    top: 0;
    left: 0;
    clear: float;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    color: #ffffff;
  }

.Exp-Card-Overlay-Coloured {
    position: absolute;
    top: 0;
    left: 0;
    clear: float;
    width: 100%;
    height: 100%;
    background-color: rgba(99, 99, 97, 0.719);
    color: #ffffff;
  }

.Exp-Card-Text-Overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    color: white;
    transform: translate(-50%, -50%);
}

.Exp-Card-Text-Spacing {
    padding-top: 1vh;
    padding-bottom: 1vh;
}